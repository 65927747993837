import * as React from "react"
import Layout from "../components/layout"
import { Seo, LeadMagnetCTA } from "../components/index"
import {
  ServicesHome,
  TwoColumns,
  Clients,
  Testimonials,
  ValueProps,
  BlogGrid,
  Process,
} from "../components"

const IndexPage = () => (
  <Layout
    // title="We Solve Business Challenges Through Web Development, Content Strategies and Digital Marketing"
    // title="We enhance the impact of global changemakers through strategic content strategies and websites that work"
    // title="We help purpose driven companies create a greater impact on the world and local communities"
    title="Enhancing the impact of global and local changemakers"
    subtitle="We help purpose driven companies create a greater impact by turning your website and content into a 24/7 sales and marketing machine"
    // title="We develop websites and create content strategies to bring you leads"
    // subtitle="Relieve your stress as we turn your website into a 24/7 sales and marketing machine"
    text="Building successful marketing strategies focused around conversion centric websites and applications"
    type="home"
  >
    <Seo
      title="CheshTech Digital Agency: Solving Business Challenges"
      description="CheshTech Digital Agency specializes in modern websites built with the latest technology and lead generation through Conversion Rate Optimization."
    />
    <ValueProps />
    <TwoColumns
      title="A Digital Agency That Aligns With Your Values"
      type="homePage"
      paragraph1="We at CheshTech are passionate at working with social impact companies and being a partner that they can rely on to help them create an impact in their commuities and the planet. We're experts at creating website and application experiences that help you reach your business goals."
      paragraph2="In addition, our focus is to bring a powerful voice to your brand and your passionate message to help change communities and the world. Check out our podcast if you're looking for a way to help spread your message. We are always looking to highlight companies doing good. Interested in starting a podcast for your own business to create awareness? We do that too!"
      // paragraph1="It can be difficult to create an effective strategy, execute that strategy, and see tangible results for your business. You may find yourself struggling to be organized with your digital marketing approach, looking to create more leads on your website, or working to find the right product iteration for the next quarter. Time is an important part of your business and not wasting it can be the difference of reaching your potential, or falling into the abyss."
      // paragraph2="That's where we come in. Our leadership team of experts specialize in creating effective digital strategies that help keep your goals organized, tasks aligned, and overall strategy connected. We help you create a system that's optimized to get you the results you desire. Our job is to invest in you and your company to bring you the growth you deserve. Interested in learning more? Set up a free consultation with a member of our leadership team."
    />
    <ServicesHome />
    <Clients />
    <Process type="home" />
    <LeadMagnetCTA />
    <BlogGrid />
    <Testimonials />
  </Layout>
)

export default IndexPage
// <LeadershipTeam color={Colors.darkBlue} />
// <Work type="home" />
